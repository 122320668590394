import styled from 'styled-components';
import {PropTypes} from 'prop-types';
import {Container, Row} from 'reactstrap';
import {NotificationsContext} from 'components/contexts/notifications';
import NotificationMessages from 'components/ui/notification/NotificationMessages.tsx';

import {DefaultMobileMenu} from 'components/ui/layouts/menus/DefaultMobileMenu';

const MobilePageRow = styled(Row)`
    position: absolute;
    width: 100%;
    min-height: calc(100% - 45px);
    background-color: #eef2ee;

    .modal-open & {
        padding-right: 15px;
    }

    .mobile-content {
        width: 100%;
    }
`;

const DefaultMobileLayout = ({pageId, children}) => {
    return (
        <Container fluid>
            <Row>
                <DefaultMobileMenu activeNavItem={pageId} />
            </Row>
            <MobilePageRow>
                <div className="mobile-content" data-testid={`page-${pageId}`}>
                    {children}
                </div>
            </MobilePageRow>
            <NotificationsContext.Consumer>
                {(notificationMessages) => {
                    if (
                        !notificationMessages ||
                        notificationMessages.length === 0
                    )
                        return null;
                    return (
                        <NotificationMessages messages={notificationMessages} />
                    );
                }}
            </NotificationsContext.Consumer>
        </Container>
    );
};

DefaultMobileLayout.propTypes = {
    pageId: PropTypes.string.isRequired,
    children: PropTypes.node,
};

export {DefaultMobileLayout};
