import PropTypes from 'prop-types';
import Router from 'next/router';
import {useSession} from 'next-auth/client';
import {UserQueryProvider} from 'components/providers/UserQueryProvider';
import {LOGIN_URL} from 'components/util/routeConstants';

function AuthenticationRouter({children}) {
    const [session, loading] = useSession();
    if (loading) return null;

    if (!session) {
        Router.push(LOGIN_URL);
        return null;
    }

    return (
        <UserQueryProvider userId={session.user.userId}>
            {({user}) => {
                return children({user});
            }}
        </UserQueryProvider>
    );
}

AuthenticationRouter.propTypes = {
    children: PropTypes.any,
};

export {AuthenticationRouter};
